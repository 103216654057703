import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./../../api/axios";
import { fetchOffers } from "./offersSlice";
import { fetchCurrentUser } from "./userSlice";
const initialState = {
  applications: [],
  status: "idle",
  error: null,
  ToEditApplication: "",
  deleteSatus: "idle",
  deleteError: null,
  oneApplication: "",
  fetchOneStatus: "idle",
  fetchOneError: null,
  updateStatus: "idle",
  updateError: null,
};

export const apply = createAsyncThunk(
  "applications/apply",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(
        `applications/offers/${values.offer}/apply`,
        values.formData
      );
      data = await response.data;
      if (response.data.status === "success") {
        thunkAPI.dispatch(fetchCurrentUser());
        thunkAPI.dispatch(
          fetchOffers({
            active: true,
            "ExpiresIn[gte]": new Date(),
            "startsAt[lte]": new Date(),
            section: values.sectionId,
          })
        );
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const deleteApplication = createAsyncThunk(
  "applications/deleteApplication",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.delete(`applications/${values.id}`);
      data = await response.data;
      if (response.data.status === "Success") {
        thunkAPI.dispatch(
          fetchOffers({
            active: true,
            "ExpiresIn[gte]": new Date(),
            "startsAt[lte]": new Date(),
            section: values.sectionId,
          })
        );
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const fetchApplication = createAsyncThunk(
  "applications/fetchApplication",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(`applications/${id}`);
      data = await response.data;
      if (response.data.status === "Success") {
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      throw err;
    }
  }
);

export const updateOneApplication = createAsyncThunk(
  "applications/updateOneApplication",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.put(
        `applications/update/${values.id}`,
        values.data
      );
      data = await response.data;
      if (response.data.status === "Success") {
        thunkAPI.dispatch(fetchApplication(values.id));
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      throw err;
    }
  }
);

const applicationSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    getIdOfApplication: (state, action) => {
      state.ToEditApplication = action.payload;
    },
  },
  extraReducers: {
    [apply.pending]: (state) => {
      state.status = "loading";
    },
    [apply.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.sections = action.payload;
    },
    [apply.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteApplication.pending]: (state) => {
      state.deleteSatus = "loading";
    },
    [deleteApplication.fulfilled]: (state, action) => {
      state.deleteSatus = "succeeded";
      state.ToEditApplication = '';
    },
    [deleteApplication.rejected]: (state, action) => {
      state.deleteSatus = "failed";
      state.deleteError = action.error.message;
    },
    [fetchApplication.pending]: (state) => {
      state.fetchOneStatus = "loading";
    },
    [fetchApplication.fulfilled]: (state, action) => {
      state.fetchOneStatus = "succeeded";
      state.oneApplication = action.payload.data;
    },
    [fetchApplication.rejected]: (state, action) => {
      state.fetchOneStatus = "failed";
      state.fetchOneError = action.error.message;
    },
    [updateOneApplication.pending]: (state) => {
      state.updateStatus = "loading";
    },
    [updateOneApplication.fulfilled]: (state, action) => {
      state.updateStatus = "succeeded";
      state.oneApplication = action.payload.data;
    },
    [updateOneApplication.rejected]: (state, action) => {
      state.updateStatus = "failed";
      state.updateError = action.error.message;
    },
  },
});
export const { getIdOfApplication } = applicationSlice.actions;

export default applicationSlice.reducer;
