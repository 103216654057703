import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import App from "./App"
import { Provider } from "react-redux";
import { store } from "./store";

ReactDOM.render(
     <Provider store={store}>
    <App />
    </Provider>,
  document.getElementById("root")
);
reportWebVitals()
