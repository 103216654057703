import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

const initialState = {
  offers: [],
  status: "idle",
  error: null,
  latestOffers: [],
  latestOffersStatus: "idle",
  latestOffersError: null
};

export const fetchOffers = createAsyncThunk(
  "offers/fetchOffers",
  async (query) => {
    let data;
    try {
      const response = await axiosInstance.get(`offers`, {
        params: query,
      });
      data = await response.data;
      if ((response.status === 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchlastOffers = createAsyncThunk(
  "offers/fetchlastOffers",
  async () => {
    let data;
    try {
      const response = await axiosInstance.get(`offers/lastOffers`);
      data = await response.data;
      if ((response.status ===   200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const offersSlice = createSlice({
  name: "levels",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOffers.pending]: (state) => {
      state.status = "loading";
    },
    [fetchOffers.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.offers = action.payload;
    },
    [fetchOffers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchlastOffers.pending]: (state) => {
      state.latestOffersStatus = "loading";
    },
    [fetchlastOffers.fulfilled]: (state, action) => {
      state.latestOffersStatus = "succeeded";
      state.latestOffers = action.payload.data;
    },
    [fetchlastOffers.rejected]: (state, action) => {
      state.latestOffersStatus = "failed";
      state.latestOffersError = action;
    },
  },
});
export default offersSlice.reducer;
