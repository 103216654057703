import React, { useEffect } from 'react'
import routes, { RenderRoutes } from "./routes/routes";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./auth/JWTauthContext"
import { useDispatch } from "react-redux";
import { fetchCurrentUser } from './store/slices/userSlice';

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      dispatch(fetchCurrentUser())
    }
  }, [])
  return (
    <AuthProvider>
      <BrowserRouter>
        <RenderRoutes routes={routes} />
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App