import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import notificationSlice from "./slices/notificationsSlice";
import sectionSlice from "./slices/sectionSlice";
import offersSlice from "./slices/offersSlice";
import applicationSlice from "./slices/applicationSlice"
export const store = configureStore({
  reducer: {
    sectionSlice: sectionSlice,
    userSlice: userSlice,
    notificationSlice: notificationSlice,
    offersSlice: offersSlice,
    applicationSlice: applicationSlice,
  },
});
