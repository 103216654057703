import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./../../api/axios";
const initialState = {
    notifications: [],
    status: "idle",
    error: null,
};
export const fetchNotifications = createAsyncThunk("notifications/fetchNotifications", async () => {
    let data
    try {
        const response = await axiosInstance.get(`api/notifications/my`
        );
        data = await response.data;
        if ((response.status = 200)) {
            return data;
        }
        throw new Error(response.statusText);
    } catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchNotifications.pending]: (state) => {
            state.status = "loading";
        },
        [fetchNotifications.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.notifications = action.payload.data;
        },
        [fetchNotifications.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
    },
});
export default notificationSlice.reducer;
