import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axiosInstance from "../api/axios";
import LoadingScreen from "../components/loadingScreen/index.jsx";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};
// check is the token is valid
const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};
// get the user id after decoding token
const getUserId = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  return decoded.id;
};
// set token to local storage
const setSession = (token) => {
  if (token) {
    localStorage.setItem("token", token);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem("token");
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};
const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      };
    }
    case "FORGOT": {
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      };
    }
    case "RESET": {
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      };
    }
    case "CONFIRM": {
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      };
    }
    case "ACTIVATE": {
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      };
    }
    case "RESENDCODEACTIVATE": {
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      };
    }
    case "RESENDCODERESET": {
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      };
    }
    default: {
      return { ...state };
    }
  }
};
const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const login = async (email, password) => {
    const response = await axiosInstance.post("users/login", {
      email,
      password,
    });

    const { token } = response.data;
    setSession(token);
    let id = getUserId(token);
    dispatch({
      type: "LOGIN",
      payload: {
        id,
      },
    });
  };
  const logout = async () => {
    await axiosInstance.get("users/logout");
    setSession();
    dispatch({ type: "LOGOUT" });
  };
  const forgotPassword = async (email) => {
    const response = await axiosInstance.post(`users/forgotPassword`, {
      email,
    });
    dispatch({
      type: "FORGOT",
    });
    return response;
  };
  const resetPassword = async (email, code) => {
    const response = await axiosInstance.post(
      `users/verifycode?email=${email}`,
      {
        code,
      }
    );
    dispatch({
      type: "RESET",
    });
    return response;
  };
  const confirmPassword = async (email, data) => {
    const response = await axiosInstance.post(
      `users/resetpassword?email=${email}`,
      {
        password: data.newPassword,
        passwordConfirm: data.confirmNewPassword,
      }
    );

    const { token } = response.data;
    setSession(token);
    dispatch({
      type: "CONFIRM",
      payload: {
        token,
      },
    });
    return response;
  };
  const activateAccount = async (email, code) => {
    const response = await axiosInstance.post(
      `users/activateAccount?email=${email}`,
      {
        code,
      }
    );
    dispatch({
      type: "ACTIVATE",
    });
    return response;
  };
  const resendCodeToActivate = async (email) => {
    const response = await axiosInstance.post(
      `users/resendActivationCode?email=${email}`
    );
    dispatch({
      type: "RESENDCODEACTIVATE",
    });
    return response;
  };
  const resendCodeToResetPassword = async (email) => {
    const response = await axiosInstance.post(
      `users/resendResetPasswordCode?email=${email}`
    );
    dispatch({
      type: "RESENDCODERESET",
    });
    return response;
  };
  useEffect(() => {
    const initialise = async () => {
      try {
        const token = window.localStorage.getItem("token");
        if (token && isValidToken(token)) {
          setSession(token);
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          setSession()
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialise();
  }, []);
  if (!state.isInitialised) {
    return <LoadingScreen />;
  }
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        forgotPassword,
        resetPassword,
        confirmPassword,
        activateAccount,
        resendCodeToActivate,
        resendCodeToResetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
