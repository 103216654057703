import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./../../api/axios";
const initialState = {
  sections: [],
  oneSection: "",
  status: "idle",
  error: null,
};
export const fetchSections = createAsyncThunk(
  "sections/fetchSections",
  async (query) => {
    let data;
    try {
      const response = await axiosInstance.get(`sections`, {params: query});
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const sectionSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSections.pending]: (state) => {
      state.status = "loading";
    },
    [fetchSections.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.sections = action.payload.data;
    },
    [fetchSections.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export default sectionSlice.reducer;
