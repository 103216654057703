import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import LoadingScreen from "../components/loadingScreen";
import AuthGuard from "./AuthGuard";

export const RenderRoutes = ({ routes = [] }) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Guard = route.guard || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    <RenderRoutes routes={route.routes} />
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("../views/Home")),
  },
  {
    exact: true,
    path: "/map",
    component: lazy(() => import("../components/homePageComponents/Map/Map")),
  },
  {
    exact: true,
    path: "/register",
    component: lazy(() => import("../views/Register/Register")),
  },
  {
    exact: true,
    path: "/login",
    component: lazy(() => import("../views/Login/Login")),
  },
  {
    exact: true,
    path: "/ForgotPassword",
    component: lazy(() => import("../views/ForgotPassword/ForgetPassword")),
  },
  {
    exact: true,
    path: "/ActivateYourAccount",
    component: lazy(() => import("../views/ActivateYourAccount/ActivateAccount")),
  },

  // user routes
  {
    guard: AuthGuard,
    exact: true,
    path: "/companyPage",
    component: lazy(() => import("../views/companyPage/CompanyPage")),
  },
  {
    guard: AuthGuard,
    exact: true,
    path: "/usersettings",
    component: lazy(() => import("../views/UserSettings/UserSetings")),
  },
  {
    guard: AuthGuard,
    exact: true,
    path: "/inbox",
    component: lazy(() => import("../views/Inbox/Inbox")),
  },
  {
    guard: AuthGuard,
    exact: true,
    path: "/apply/:offerId",
    component: lazy(() => import("../views/form/FormBacAlternance")),
  },
  {
    guard: AuthGuard,
    exact: true,
    path: "/finishApplication",
    component: lazy(() => import("../components/finishApplication/FinishApplication")),
  },
  {
    path: "*",
    component: lazy(() => import("../views/NotFound/NotFound")),
  },
];

export default routes;
