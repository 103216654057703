import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import useAuth from "./../hooks/useAuth";

const AuthGuard = ({ children }) => {
  const location = useLocation()
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: location.pathname,
      }}
    />
  );
};
export default AuthGuard;
